<template>
  <v-select
    v-model="view"
    :items="options"
    v-bind="{
      outlined: true,
      dense: true,
      ...$attrs,
      itemValue: value,
      itemText: 'text',
    }"
  />
</template>
<script>
import { PRACTICES_VIEWS } from '@/services/practices-settings'
import { createEnumProp } from '@/utils/props'

export default {
  props: {
    value: createEnumProp({
      values: PRACTICES_VIEWS,
    })
  },
  data () {
    return {
      options: Object
        .values(PRACTICES_VIEWS)
        .map((value) => ({ text: value, value }))
    }
  },
  computed: {
    view: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
