export function createEnumProp (options) {
  const {
    values,
    default: defaultValue,
    required = false
  } = options
  return {
    type: String,
    required,
    default: defaultValue,
    validator: (prop) => Object.values(values).includes(prop)
  }
}
