<template>
  <div>
    <div v-for="item in items" class="mb-4" :key="item.name">
      <div class="text-h6">
        {{ item.name.ru || item.name.en }}
      </div>
      <div v-if="item.code">
        Code: {{ item.code }}
      </div>
      <div v-for="contact in item.contacts" :key="contact.name">
        {{ contact.name }}: {{ contact.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    items () {
      return this.value.map((item) => {
        const { name, code, comment, disabled, ...contacts } = item
        return {
          name,
          code,
          comment,
          disabled,
          contacts: Object.entries(contacts)
            .map(([name, value]) => ({ name, value }))
            .filter(({ value }) => value),
        }
      })
    }
  }
}
</script>
