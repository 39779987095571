import BaseService from './baseService';
import api from '../utils/api'
import omit from "lodash/omit"

export const PRACTICES_VIEW_BOX = 'box'
export const PRACTICES_VIEW_CARD = 'card'
export const PRACTICES_VIEWS = {
  PRACTICES_VIEW_BOX,
  PRACTICES_VIEW_CARD
}

export const SORTING_TYPE_MANUAL = 'manual'
export const SORTING_TYPE_RANDOM = 'random'
export const SORITNGS = {
  SORTING_TYPE_MANUAL,
  SORTING_TYPE_RANDOM,
}

class PracticesSettingsService extends BaseService {
  get url () {
    return 'practices'
  }

  async getAll () {
    const settings = await api.get(this.url)

    return omit({
      ...settings,
      image: {
        desktop: settings.mainWidePhoto,
        mobile: settings.mainSquarePhoto,
      },
    }, [
      'mainSquarePhoto',
      'mainWidePhoto',
    ])
  }

  toDto (entity) {
    return omit({
      ...entity,
      mainSquarePhotoId: entity.image?.mobile?.id,
      mainWidePhotoId: entity.image?.desktop?.id,
    }, [
      'image',
    ])
  }

  update (settings) {
    return api.patch(this.url, settings)
  }
}

export const practicesSettingsService = new PracticesSettingsService()

