import BaseService from './baseService';
import api from '../utils/api'
import { ItemMeta } from '../models'
import { locales } from '../store/modules/app';
import omit from "lodash/omit";
import get from "lodash/get";

export const STATUS_PUBLISHED = 'published'
export const STATUS_DRAFT = 'draft'
export const SORTING_TYPE_MANUAL = 'manual'
export const SORTING_TYPE_RANDOM = 'random'


function createLocaleField (keys = locales, defaultValue = null) {
  return keys.reduce((acc, key) => ({ ...acc, [key]: defaultValue }), {})
}

class PracticesService extends BaseService {
  get url () {
    return 'practices/items'
  }

  createModel () {
    return {
      organizationId: null,
      id: null,
      name: {},
      journalPosts: [],
      galleryPhotos: [],
      blocks: [],
      gallery: [],
      generalManagers: [],
      generalManagerTitle: null,
      attachedDoctors: [],
      attachedServices: [],
      mainWidePhotoUrl: null,
      mainSquarePhotoUrl: null,
      socialImage: null,
      metaDescription: {},
      description: createLocaleField(),
      status: STATUS_DRAFT,
      contacts: [],
      meta: new ItemMeta(),
    }
  }


  toDto (entity) {
    let n = omit(entity, [
      'socialImage',
      'attachedDoctors',
      'attachedServices',
      'mainSquarePhoto',
      'mainWidePhoto',
    ])
    n.socialImageId = get(entity, 'socialImage.id', null)
    n.attachedDoctorIds = entity.attachedDoctors.map(({ id }) => id)
    n.attachedServiceIds = entity.attachedServices.map(({ id }) => id)
    n.journalPosts = entity.journalPosts.map((journalPost) => ({
      postId: journalPost.post.uuid,
      order: journalPost.order
    }))
    n.gallery = entity.gallery.map(({ id, title, photo, type, embeddedVideo }) => ({
      id,
      type,
      title,
      embeddedVideo,
      photoId: photo?.id
    }))
    n.organizationId = n.organization?.id
    n.cardImageId = entity.cardImage?.id
    n.mainSquarePhotoId = entity.mainSquarePhoto?.id
    n.mainWidePhotoId = entity.mainWidePhoto?.id
    return n
  }

  reorder (practices) {
    const items = practices.map((practice, idx) => ({
      practiceId: practice.id,
      order: idx
    }))
    return api.post(`${this.url}/order`, { items })
  }

}

export const practicesService = new PracticesService()

