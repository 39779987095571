<template>
  <div class="slug-editor" no-gutter>
    <div class="slug-editor__main">
      <slug-input
        class="slug-editor__input"
        ref="input"
        v-bind="attrs"
        v-on="listeners"
      />
      <div class="slug-editor__messages" v-if="messages.length">
        <div v-for="message in messages" :class="message.class" :key="message.key">
          {{ message.text }}
        </div>
      </div>
    </div>
    <div class="slug-editor__actions">
      <template v-if="editable">
        <v-btn
          @click="sync"
          v-bind="{ ...buttonStyle, title: 'Синхронизировать' }"
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn
          v-bind="{ ...buttonStyle, title: 'Применить' }"
          @click="lock"
        ><v-icon>mdi-check</v-icon></v-btn>
        <v-btn
          v-bind="{ ...buttonStyle, title: 'Отменить' }"
          @click="cancel"
          class="ml-4"
          style="color: #fff"
          color="red"
        ><v-icon>mdi-close</v-icon></v-btn>
      </template>
      <template v-else>
        <v-btn
          @click="edit"
          v-bind="{ ...buttonStyle, title: 'Изменить' }"
        >
          <v-icon>mdi-pen</v-icon>
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import SlugInput from './SlugInput.vue'
import { makeSlug } from '@/utils/format'

export default {
  components: {
    SlugInput,
  },
  props: {
    input: {},
    value: {},
    example: {},
    error: {},
    path: {
      type: String
    }
  },
  data () {
    return {
      editable: false,
      oldValue: null,
      slug: this.value,
    }
  },
  computed: {
    buttonStyle () {
      return { depressed: true, 'x-small': true, fab: true }
    },
    attrs () {
      return {
        ...this.$attrs,
        value: this.slug,
        // outlined: true,
        // dense: true,
        disabled: !this.editable,
        hint: null,
        error: !!this.error
      }
    },
    listeners () {
      return {
        ...this.$listeners,
        input: (value) => {
          this.slug = value
        },
        error: (e) => {
          console.error(e)
          this.$emit('error', e)
        }
      }
    },
    messages () {
      return [
        {
          key: 'error',
          text: this.error,
          class: 'red--text text-caption'
        },
        {
          key: 'example',
          text: this.example,
          class: 'grey--text text-caption'
        },
        {
          key: 'path',
          text: this.path,
          class: 'grey--text text-caption'
        }
      ].filter(({ text }) => text)
    }
  },
  watch: {
    slug (slug) {
      this.$emit('input', this.slug)
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    sync () {
      this.slug = makeSlug(this.input || '')
      this.focus()
    },
    edit () {
      this.editable = true
      this.oldValue = this.slug
      this.$nextTick(() => this.focus())
    },
    lock () {
      this.oldValue = null
      this.editable = false
    },
    cancel () {
      this.slug = this.oldValue
      this.lock()
    },
    toggleEdit () {
      if (this.editable) {
        this.lock()
      } else {
        this.edit()
      }
    }
  }
}
</script>

<style scoped>
.slug-editor {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
}

.slug-editor__main {
  flex: 1 1 auto;
}

.slug-editor__actions {
  margin-top: 12px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  grid-gap: 12px;
}

.slug-editor__messages > * {
  margin-top: 4px;
}
</style>
