<template>
  <v-text-field
    ref="input"
    v-bind="attrs"
    v-on="listeners"
  />
</template>

<script>
const regexp = /^[a-zA-Z0-9а-яА-Я-_]+$/
const isValidInput = (value) => regexp.test(value)
const MSG_INVALID_VALUE = 'Недопустимое значение'

export default {
  props: {
    value: {}
  },
  data () {
    return {
      slug: this.value
    }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        value: this.value,
      }
    },
    listeners () {
      return {
        ...this.$listeners,
        paste: (e) => {
          const value = e.clipboardData.getData('Text')
          if (!isValidInput(value)) {
            e.preventDefault()
            this.showInvalidValue()
          } else {
            this.$emit('paste', e)
          }
        },
        keydown: (e) => {
          if (!isValidInput(e.key)) {
            e.preventDefault()
            this.showInvalidValue()
          } else {
            this.$emit('keydown', e)
          }
        },
        input: (e) => {
          this.$emit('input', e)
        }
      }
    }
  },
  watch: {
    slug (slug, oldValue) {
      this.$emit('input', slug)
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    showInvalidValue () {
      this.$emit('error', { message: MSG_INVALID_VALUE })
    }
  }
}
</script>
